
  
  
  .project-grid-box {
    /* background: red; */
    margin: 0 auto;
    padding: 3.2rem;
    display: grid;
    place-items: center;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
    gap: 4.8rem;
    max-width: 130rem;
    /* autofit fits to its column, or 
    minimum width of the column = 300px
    maximum width of the column = 1fr
    maximum - width: 100%; 
     130rem === 1300px 
     maximum width of the container! */
  }
  .project-heading {
    color: #333;
    text-align: center;
    font-size: 4.8rem;
    padding: 2.4rem 0;
  }
  .project {
    background: #1e90ff;
    width: 32rem;
    border-radius: 1rem;
    position: relative;
    overflow: hidden;
    transition: all 0.3s;
  }
  .project:hover {
    transform: scale(1.02);
    box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.4);
  }
  .project span {
    position: absolute;
    top: 0;
    left: 0;
    height: 50%;
    width: 100%;
    background: linear-gradient(rgba(255, 255, 255, 0.3), #1e8fffcb);
    color: #000000;
    font-size: 2rem;
    border-radius: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4.8rem;
    transform: translateY(-100%);
    transition: all 0.5s ;
    transition-delay: 0.3s;
  }
  .project span i {
    font-size: 5.2rem;
    color: #ffffff;
    transition: all 0.3s;
  }
  .project span i:hover {
    color: #000000;
  }
  .project:hover span {
    transform: translateY(0);
  }
  
  .project-img-box {
    text-decoration: none;
    display: inline-block;
  }
  .project-img-box h2 {
    text-align: center;
    padding: 1.5rem 0;
    color: #fff;
    font-size: 1 rem;
  }
  .project-img-box img {
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 1rem 1rem 0 0;
  }

