.update
{
    justify-content: center;
    padding: 10px;
    margin: 20px;
    width: 400PX;
    border: 10px;
    justify-items: center;
    background-color: bisque;
    margin-left: 35%;
}
.update button
{
    border: none;
    padding: 10px;
    transition: all;
}