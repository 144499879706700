@import url("https://fonts.googleapis.com/css?family=Poppins:400,500,600,700");
 @font-face {
	 font-family: "Primal Regular";
	 src: url("https://res.cloudinary.com/overdrive/raw/upload/v1539056166/fonts/primal-webfont.woff2") format("woff2"), url("https://res.cloudinary.com/overdrive/raw/upload/v1539056165/fonts/primal-webfont.woff") format("woff");
	 font-weight: normal;
	 font-style: normal;
}

.mainProject {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
	font-size: 16px;
	line-height: 30px;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	background-color: #0c0e27;
	color: white;
	font-family: "Poppins", sans-serif;
	font-weight: 400;
}

 #homeProject a {
	 color: white;
	 transition: all 0.5s ease;
}
 #homeProject a:hover {
	 color: #00e4ff;
	 text-decoration: none;
}
 #homeProject .btn-default {
	 background: #00e4ff;
}
 #homeProject .btn-gradient {
	 background-image: linear-gradient(to right, #00e4ff 0%, #a6c1ee 51%, #fbc2eb 100%);
	 transition: 0.5s;
	 background-size: 200% auto;
	 color: #fff;
	 border: 2px solid #00e4ff;
	 border-radius: 1.5625em;
	 padding: 0.625em 1.5625em;
	 font-weight: 500;
	 text-transform: uppercase;
}
 #homeProject .btn-gradient:hover {
	 background-position: right center;
	 color: white;
	 border-color: #fbc2eb;
}
 #homeProject .btn-outline {
	 transition: 0.5s;
	 background-image: linear-gradient(to right, #00e4ff 0%, #a6c1ee 51%, #fbc2eb 100%);
	 background-size: 200% auto;
	 color: #fff;
	 padding: 12px 4px;
	 border-radius: 1.6em;
}
 #homeProject .btn-outline:hover {
	 background-position: right center;
	 color: #fff;
}
 #homeProject .btn-outline span {
	 border-radius: 1.6em;
	 background: #0c0e27;
	 padding: 0.625em 1.5625em;
	 font-weight: 500;
	 text-transform: uppercase;
}
 #homeProject .bg-gradient {
	 background-image: linear-gradient(to right, #00e4ff 0%, #a6c1ee 51%, #fbc2eb 100%);
}
 #homeProject .section-title {
	 font-weight: bold;
	 font-size: 1.625em;
	 margin-bottom: 1.2em;
	 padding-bottom: 0.6em;
	 position: relative;
	 text-align: center;
}
 #homeProject .section-title::before {
	 background-color: #fff;
	 margin: 0 auto;
	 bottom: 0;
	 content: "";
	 height: 2px;
	 left: 0;
	 position: absolute;
	 right: 0;
	 width: 80px;
	 box-sizing: border-box;
}
strong{
	color:#fff;
}

/*===================================* 03. HOME *===================================*/
 #homeProject {
	 margin-top: 13em;
}
#homeProject .badge-default {
	 color: white;
	 font-size: 1em;
}
#homeProject .banner-effect {
	 bottom: 0;
	 height: 100%;
	 left: 0;
	 position: absolute;
	 right: 0;
	 top: 0;
}
#homeProject .list-currency {
	 list-style: none;
	 margin: 1em 0;
	 padding: 0;
}
#homeProject .list-currency li {
	 display: inline-block;
	 margin-right: 1em;
}
 .list-currency li span {
	 margin-left: 0.5em;
}
 .list-currency li:last-child {
	 margin-right: 0;
}
