
.button {
    padding: 1rem 1rem;
    border: none;
    font-weight: bold;
    font-size: 1rem;
    color: rgb(39, 35, 35);
    cursor: pointer;
 
    background: repeating-linear-gradient(75grad, rgba(0,0,0,0) 25%,rgba(255,255,255,0.6) 26%, rgba(0,0,0,0.0) 50%, rgba(0,0,0,0) 100%),darkgray;;
    

    animation: mymove 5s infinite linear; 
    box-shadow: 0px 1px 5px rgba(0,0,0,0.4);
}
.button:focus {
    outline: none;
}
.box {
    position: relative;
    overflow: hidden;
    width: 170PX;
    animation: mymove 5s infinite linear;
    background: repeating-linear-gradient(75grad, rgba(44, 49, 59, 0) 25%,rgba(255,255,255,0.6) 26%, rgba(141, 134, 134, 0) 50%, rgba(191, 185, 185, 0) 100%),darkgray;;
    box-shadow: 0px 1px 5px rgba(0,0,0,0.4);
}
.box span {
    position: absolute;
}
.box span:nth-of-type(1) {
    height: 1px;
    width: 100%;
    background-image: linear-gradient(to right, black, #fff, black);
    top: 0;
    left: 0;
    animation: MR 1s ease infinite alternate;
}
.box span:nth-of-type(4) {
    height: 1px;
    width: 100%;
    background-image: linear-gradient(to right, black, #fff, black);
    bottom: 0;
    right: 0;
    animation: ML 1s ease infinite alternate;
}
.box span:nth-of-type(2) {
    width: 1px;
    height: 100%;
    background-image: linear-gradient(to bottom, black, #fff, black);
    bottom: 0;
    left: 0;
    animation: MU 1s ease infinite alternate;
}
.box span:nth-of-type(3) {
    width: 1px;
    height: 100%;
    background-image: linear-gradient(to bottom, black, #fff, black);
    bottom: 0;
    right: 0;
    animation: MD 1s ease infinite alternate;
}
@keyframes MR {
    0% {
        left: -100%;
   }
    100% {
        left: 100%;
   }
}
@keyframes ML {
    0% {
        right: -100%;
   }
    100% {
        right: 100%;
   }
}
@keyframes MU {
    0% {
        top: -100%;
   }
    100% {
        top: 100%;
   }
}
@keyframes MD {
    0% {
        bottom: -100%;
   }
    100% {
        bottom: 100%;
   }
}
.box:hover span {
    animation-play-state: paused;
}
